<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="search"
          hide-details
          prepend-inner-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
        <v-spacer/>
      </v-toolbar>
      <div class="my-content md-content">
        <v-list>
          <v-list-item-group v-model="selected" color="primary">
            <template v-for="item in filteredItems">
              <v-list-item :key="filteredItems.indexOf(item)">
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.key"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="item.key"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <Detail v-if="selected != undefined"/>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import Detail from "../components/list/Detail";
import js2vm from "../utils/js2vm";

let Item = function(d) {
  let self = this
  js2vm(self, d)
};

export default {
  data: () => ({
    selected: undefined,
    items: [],
    search: "",
    item: null,
  }),
  methods: {
    async fetchDicts() {
      this.items = await fetch.get("/api/dict/get-list");
    },
    clearSearch() {
      this.search = "";
      this.selected = undefined;
    },
  },
  watch: {
    selected: function (val) {
      if (val == undefined) return this.item = undefined
      this.item = new Item(this.filteredItems[val])
    }
  },
  computed: {
    filteredItems() {
      if (!this.search || this.search === null || 0 === this.search)
        return this.items;
      return _.orderBy(
        this.items.filter(item => {
          return (
            item.title.toLowerCase().includes(this.search.toLowerCase()) ||
            item.key.toLowerCase().includes(this.search.toLowerCase())
          );
        }),
        "headline"
      );
    }
  },
  mounted() {
    this.fetchDicts();
  },
  components: {
    Detail
  }
};
</script>
