<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="$parent.items"
    sort-by="id"
    :items-per-page="-1"
    item-key="id"
    hide-default-footer
    fixed-header
    :single-select="true"
    show-select
    @click:row="rowClick"
  >
    <template v-slot:top>
      <v-toolbar class="my-bar">
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="rightFilter('w')" icon v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.id"
                      readonly
                      label="id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.key"
                      label="Ключ"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.value"
                      label="Значение"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">
                Отмена
              </v-btn>
              <SaveButton
              color="primary"
              content="OK"
              :handler="save"
              >

              </SaveButton>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="rightFilter('w')" icon :disabled="selected.length == 0" @click="editItem">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn v-if="rightFilter('d')" icon :disabled="selected.length == 0" @click="deleteItem">
          <v-icon>remove</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить элемент справочника?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import rightFilter from "../../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

export default {
  components: {SaveButton},
  data: () => ({
    rightFilter,
    selected: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Ключ", sortable: false, value: "key" },
      { text: "Значение", sortable: false, value: "value" }
    ],
    editedIndex: -1,
    editedItem: {
      id: null,
      key: "",
      value: ""
    },
    defaultItem: {
      id: null,
      key: "",
      value: ""
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новая запись" : "Редактирование записи";
    }
  },
  watch: {
    "$parent.items": function() {
      this.selected = [];
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },

    editItem() {
      this.editedIndex = this.$parent.items.indexOf(this.selected[0]);
      this.editedItem = Object.assign({}, this.selected[0]);
      this.dialog = true;
    },

    deleteItem() {
      this.editedIndex = this.$parent.items.indexOf(this.selected[0]);
      this.editedItem = Object.assign({}, this.selected[0]);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$parent.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.$parent.items[this.editedIndex], this.editedItem);
      } else {
        this.$parent.items.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
