<template>
  <div class="view">
    <v-toolbar class="my-bar">
<!--      <v-btn v-if="rightFilter('w')" icon @click="save">
        <v-icon>save</v-icon>
      </v-btn>-->
      <SaveButton
      :handler="save"
      >

      </SaveButton>
      <v-spacer></v-spacer>
      <v-btn icon @click="$parent.selected = undefined">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="my-content">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="title"
                :label="'Наименование'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="key"
                :label="'Ключ'"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <Items />
    </div>
  </div>
</template>

<script>
import Items from "./Items";
import vm2js from "../../utils/vm2js";
import fetch from "../../utils/fetch";
import rightFilter from "../../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

export default {
  data: () => ({
    rightFilter,
    id: null,
    key: null,
    title: null,
    items: []
  }),
  methods: {
    init(item) {
      Object.assign(this, item);
    },
    async save() {
      const item = await fetch.post("/api/dict/save", vm2js(this));
      if (item) {
        Object.assign(this.$parent.filteredItems[this.$parent.selected], item);
        this.init(item);
      }
    }
  },
  watch: {
    "$parent.item": function(val) {
      this.init(val);
    }
  },
  mounted() {
    this.init(this.$parent.item);
  },
  components: {
    SaveButton,
    Items
  }
};
</script>
